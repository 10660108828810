import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";

export default function Login() {
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
  }));
  const classes = useStyles();

  const [email, setUsername] = useState("");
  const [password, setPassword] = useState("");

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
  }

  return (
    <>
      <h1>Login</h1>
      <form className={classes.root}>
        <TextField
          variant="filled"
          id="username"
          label="Username"
          value={email}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          variant="filled"
          id="password"
          label="Password"
          value={password}
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button size="large" type="submit" color="primary">
          Login
        </Button>
      </form>
    </>
  );
}
