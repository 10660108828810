import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

class ROMob extends React.Component {
  static get source() {
    return "https://rochargen.titanro.net/monster/";
  }

  static defaultProps = {
    mobID: 1002,
    name: "Poring",
  };

  render() {
    return (
      <>
        <Tooltip title={this.props.name}>
          <img src={ROMob.source + this.props.mobID} />
        </Tooltip>
      </>
    );
  }
}

export default ROMob;
