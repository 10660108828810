import "#components/App.css";
import NavigationBar from "#components/NavigationBar";
import Routes from "#components/Routes";
import React from "react";
import { Route, Switch } from "react-router-dom";

export default class App extends React.Component {
  render() {
    return (
      <div>
        <NavigationBar routes={Routes}>
          <Switch>
            {Routes.map((route) => {
              return (
                <Route exact path={route.path} key={route.path}>
                  <route.component />
                </Route>
              );
            })}
          </Switch>
        </NavigationBar>
      </div>
    );
  }
}
