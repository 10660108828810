import React, { useState } from "react";
import InventoryTable from "#components/InventoryTable";
import { gql, useLazyQuery } from "@apollo/client";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// TODO: Maybe move queries to separate folder? Maybe keep with components?
export default function ItemDB() {
  const GET_CHARACTER_INVENTORY = gql`
    query($charName: String!, $lastCursor: String) {
      character(name: $charName) {
        charId
        name
        inventoryConnection(cursor: $lastCursor) {
          lastCursor
          inventoryItems {
            id
            nameId
            amount
            equip
            items {
              name
            }
          }
        }
      }
    }
  `;

  const [getCharacterInventory, { data, fetchMore }] = useLazyQuery(
    GET_CHARACTER_INVENTORY
  );

  const [charName, setCharName] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    getCharacterInventory({ variables: { charName } });
  }

  function loadMore(e) {
    e.preventDefault();
    fetchMore({
      variables: {
        charName,
        lastCursor: data?.character?.inventoryConnection?.lastCursor,
      },
    });
  }

  return (
    <>
      <h1>This is the ItemDB</h1>
      <form onSubmit={handleSubmit}>
        <TextField
          variant="filled"
          id="charName"
          label="Character"
          value={charName}
          onChange={(e) => setCharName(e.target.value)}
        />
        <Button size="large" type="submit" color="primary">
          Lookup inventory
        </Button>
      </form>
      <InventoryTable
        inventoryItems={data?.character?.inventoryConnection?.inventoryItems}
      />
      <form onSubmit={loadMore}>
        <Button type="submit">Load more</Button>
      </form>
    </>
  );
}
