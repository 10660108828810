import React, { useState } from "react";
import ROMob from "#components/ROMob";
import ROItem from "#components/ROItem";
import { gql, useLazyQuery } from "@apollo/client";
var sprintf = require('sprintf-js').sprintf
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

export default function DropSimulator(props) {
  const GET_MOBS = gql`
    query($mobId: Int, $name: String) {
      mobs(id: $mobId, name: $name) {
        id
        name
        lv
        drops {
          aegisName
          dropRate
          item {
            id
            name
            slots
          }
          randomOptionGroup {
            name
            optionSlots {
              rate
              optionEntries {
                min
                max
                option {
                  text
                }
              }
            }
          }
        }
      }
    }
  `;

  const [getMobs, { data }] = useLazyQuery(GET_MOBS);

  const [mobId, setMobId] = useState(0);
  const [mobName, setMobName] = useState("");

  function getRandomElement(array) {
    return array[Math.floor(Math.random() * array.length)];
  }

  function handleSubmit(e) {
    e.preventDefault();
    getMobs({ variables: { mobId } });
  }

  function pickRandomOptions(optionSlots) {
    return optionSlots.map((optionSlot) => {
      let optionEntry = getRandomElement(optionSlot.optionEntries);
      let value = Math.floor(
        Math.random() * (optionEntry.max - optionEntry.min + 1) +
          optionEntry.min
      );
      return {
        text: sprintf(optionEntry.option.text, value),
      };
    });
  }

  return (
    <>
      <h1>(Random Option) Drop Simulator</h1>
      <form onSubmit={handleSubmit}>
        <TextField
          variant="filled"
          id="mobId"
          label="MobID"
          value={mobId}
          onChange={(e) => setMobId(parseInt(e.target.value))}
        />
        <Button size="large" type="submit" color="primary">
          Generate Drops
        </Button>
      </form>
      <div>
        {data?.mobs?.map((mob) => (
          <>
            <ROMob mobID={mob.id} name={mob.name} />
            <br />
            {mob?.drops?.map((drop) => (
              <>
                {drop.randomOptionGroup && (
                  <ROItem
                    name={drop.item.name}
                    itemID={drop.item.id}
                    attributes={pickRandomOptions(
                      drop.randomOptionGroup.optionSlots
                    )}
                    slots={drop.item.slots}
                  />
                )}
              </>
            ))}
          </>
        ))}
      </div>
    </>
  );
}
