import Info from "#components/Info";
import Login from "#components/Login";
import Profile from "#components/Profile";
import ItemDB from "#components/ItemDB";
import DropSimulator from "#components/DropSimulator";

const Routes = [
  {
    path: "/itemdb",
    sidebarName: "Item DB",
    component: ItemDB,
  },
  {
    path: "/dropsim",
    sidebarName: "Drop Simulator",
    component: DropSimulator,
  },
];

export default Routes;
