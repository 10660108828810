import React from "react";
import ROItemIcon from "#components/ROItemIcon";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

export default function InventoryTable(props) {
  return (
    <TableContainer style={{ width: "auto" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 80 }}></TableCell>
            <TableCell>Item ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>{/* EquipLocation */}</TableCell>
            <TableCell>Card0</TableCell>
            <TableCell>Card1</TableCell>
            <TableCell>Card2</TableCell>
            <TableCell>Card3</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(props?.inventoryItems || []).map((inventoryItem) => (
            <TableRow key={inventoryItem.id}>
              <TableCell>
                <ROItemIcon
                  itemID={inventoryItem.nameId}
                  name={inventoryItem.items[0].name}
                />
              </TableCell>
              <TableCell>{inventoryItem.nameId}</TableCell>
              <TableCell>{inventoryItem.items[0].name}</TableCell>
              <TableCell>{inventoryItem.amount}</TableCell>
              <TableCell>
                {inventoryItem.equip !== "EQP_NONE" && inventoryItem.equip}
              </TableCell>
              <TableCell>{inventoryItem.card0}</TableCell>
              <TableCell>{inventoryItem.card1}</TableCell>
              <TableCell>{inventoryItem.card2}</TableCell>
              <TableCell>{inventoryItem.card3}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
