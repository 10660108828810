import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import "./index.css";
import App from "#components/App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";

const client = new ApolloClient({
  // TODO: Store API endpoint as env variable
  uri: "https://api.titanro.net/graphql",
  // TODO: Move cache definition to separate file.
  cache: new InMemoryCache({
    typePolicies: {
      Character: {
        keyFields: ["name"],
        fields: {
          inventoryConnection: {
            merge(existing, incoming) {
              let mergedResult = {
                lastCursor: incoming.lastCursor,
                inventoryItems: [
                  // TODO: Account for inventoryItems.id to avoid duplicates
                  ...(existing?.inventoryItems || []),
                  ...incoming.inventoryItems,
                ],
              };
              return mergedResult;
            },
            read(existing) {
              return existing;
            },
          },
        },
      },
    },
  }),
});

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <BrowserRouter>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
