import React from "react";
import styled from "styled-components";

const CollectionItem = styled.div`
  position: relative;
  background-image: url("item_collection_bg.png");
  background-repeat: no-repeat;
  min-height: 128px;
  padding-top: 128px;
`;

const CollectionImage = styled.img`
  position: absolute;
  left: 10px;
  top: 12px;
`;

const ItemName = styled.p`
  font-family: sans-serif;
  font-size: 9pt;
  position: absolute;
  left: 90px;
  top: -2px;
`;

const Description = styled.p`
  position: absolute;
  overflow-y: scroll;
  width: 182px;
  height: 93px;
  left: 94px;
  top: 18px;
  font-size: 9pt;
`;

const Sysbox = styled.div`
  width: 280px;
  height: 27px;
  border: solid transparent 14px;
  border-image: url("sysbox.png") 14;
`;

const Attribute = styled.div`
  position: relative;
  top: -7px;
  left: -2px;
  font-family: sans-serif;
  font-size: 9pt;
`;

const Card = styled.img`
  position: relative;
  top: -13px;
  left: -10px;
  margin-right: 1px;
`;
const CardNone = styled(Card)`
  content: url("disable_card_slot.png");
`;
const CardEmpty = styled(Card)`
  content: url("empty_card_slot.png");
`;

class ROItem extends React.Component {
  static get source() {
    return "https://www.divine-pride.net/img/items/collection/iRO/";
  }

  static defaultProps = {
    name: "White Potion",
    itemID: 504,
    slots: 0,
    description: [
      "A set of daggers in which the blades are positioned above the knuckles for use in close range combat.",
      "Dex +1",
      "Class:^6666CC Katar^000000",
      "Attack:^CC0000 148^000000",
      "Weight:^009900 120^000000",
      "Weapon Level:^009900 3^000000",
      "Level Requirement:^009900 33^000000",
      "Jobs:^6666CC Assassin^000000",
    ],
  };

  render() {
    return (
      <CollectionItem>
        <CollectionImage src={ROItem.source + this.props.itemID} />
        <ItemName>{this.props.name}</ItemName>
        <Description>{this.props.description}</Description>
        {(this.props?.attributes || []).map((attribute) => (
          <Sysbox>
            <Attribute>
              {attribute.text}
            </Attribute>
          </Sysbox>
        ))}
        <Sysbox>
          {[1, 2, 3, 4].map((idx) =>
            this.props.slots >= idx ? <CardEmpty /> : <CardNone />
          )}
        </Sysbox>
      </CollectionItem>
    );
  }
}

export default ROItem;
