import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

class ROItemIcon extends React.Component {
  static get source() {
    return "https://www.divine-pride.net/img/items/item/iRO/";
  }

  static defaultProps = {
    name: "White Potion",
    itemID: 504,
  };

  render() {
    return (
      <>
        <Tooltip title={this.props.name}>
          <img src={ROItemIcon.source + this.props.itemID} />
        </Tooltip>
      </>
    );
  }
}

export default ROItemIcon;
